<template>
	<div
		class="full-height"
	>
		<h6>{{ program.name }}</h6>

		<Search
			class="mt-10 box"
			:search="search"
			:option="search_option"
			:codes="codes"

			@click="getSearch"
			@toItem="toItem"
			@reset="reset"
		>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.state"
				@change="getSearch(1)"
			>
				<option
					v-for="add in codes.posStatus"
					:key="'posType_' + add.code"
					:value="add.code"
				>{{ add.code_name }}</option>
			</select>
		</Search>

		<div class="mt-10 pa-10 bg-white full-height flex-column overflow-y-auto">

			<div class="justify-space-between">
				<div>
					<select
						v-model="terminal_status"
						class="pa-5-10 mr-10 size-px-12"
					>
						<option
							v-for="(code, index) in codes.posStatus"
							:key="'terminal_status_' + index"
							:value="code.code"
						>{{ code.code_name }}</option>
					</select>

					<button
						class="box bg-identify pa-4-10 size-px-12 mr-10"
						@click="doUpdate"
					>선택 수정</button>
				</div>



				<div>
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
					<select
						class="pa-5-10 mr-10 size-px-12"
						v-model="search.size"
						@change="getSearch(1)"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>
			</div>

			<table
				v-if="items.length > 0"
				class="mt-10 table table-even"
			>
				<colgroup>
					<col width="60px" />
					<col width="80px" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="180px" />
				</colgroup>
				<thead>
				<tr>
					<th>
						<input
							v-model="is_all"
							type="checkbox"
						/>
					</th>
					<th>NO</th>
					<th>가맹점</th>
					<th>단말기 번호</th>
					<th>등록일</th>

					<th>단말기 상태</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td><input type="checkbox" v-model="item.is_select" /></td>
					<td>{{ item.terminalIdx }}</td>
					<td class="text-left">{{ item.shopName}}</td>
					<td>{{ item.serialNumber }}</td>

					<td>{{ item.regDate }}</td>
					<td><span class="pa-5-10" :class="'btn-' + item.state_color">{{ item.state_name }}</span></td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center top-line-identify mt-10"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 color-icon"
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"
				@click="getSearch"
			></Pagination>
		</div>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			@close="close"
		>
			<template
				slot="modal-content"
			>
				<TerminalItem
					:item_terminal="item_terminal"
					:item_merchant="item_merchant"
					:search_merchant="search_merchant"
					:rules="rules"
					:Axios="Axios"

					@setMerchant="setMerchant"
					@close="close"
					@clear="clear"
					@save="save"

					style="height: 350px;"
				></TerminalItem>
			</template>
		</Modal>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@finish="is_excel = !is_excel"
		></Excel>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Modal from "@/components/Modal";
import TerminalItem from "@/view/Terminal/TerminalItem";
import Excel from "@/components/Excel";

export default {
	name: 'TerminalList',
	components: {Excel, TerminalItem, Modal, Search, Pagination},
	props: ['user', 'codes', 'date', 'Axios', 'rules']
	,data: function(){
		return {
			program: {
				name: '단말기 목록'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.$route.query.page ? this.$route.query.page : 1
				,size: this.$route.query.size ? this.$route.query.size : 10
				,search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
				,search_value: this.$route.query.search_value ? this.$route.query.search_value : ''
				,agency_type: this.$route.query.agency_type ? this.$route.query.agency_type : ''
				,state: ''
			}
			,search_option: {
				search_type: [
					{ name: '가맹점', column: 'name'}
					, { name: '단말기 번호', column: 'number'}
				]
				,is_item: true
			}
			,add_agency_type: this.codes.agency_type
			,items: [
				{ no: 1, idx: 1}
				,{ no: 2, idx: 2}
				,{ no: 3, idx: 3}
				,{ no: 4, idx: 4}
				,{ no: 5, idx: 5}
			]
			,item: {

			}
			,items_merchant: [
				{ name: '가맹점 A', code: 'A', idx: 1}
				, { name: '가맹점 B', code: 'B', idx: 2}
				, { name: '가맹점 C', code: 'C', idx: 3}
				, { name: '가맹점 D', code: 'D', idx: 4}
				, { name: '가맹점 E', code: 'E', idx: 5}
				, { name: '가맹점 F', code: 'F', idx: 6}
				, { name: '가맹점 G', code: 'G', idx: 7}
			]
			,item_merchant: {

			}
			,item_terminal: {

			}
			,search_merchant: {
				name: ''
			}
			,is_excel: false
			,excel_data: {
				name: '단말기 목록'
				,header: [
					{ key: 0, name: 'NO', column: 'terminalIdx'}
					,{ key: 0, name: '가맹점', column: 'shopName'}
					,{ key: 0, name: '단말기 번호', column: 'serialNumber'}
					,{ key: 0, name: '등록일', column: 'regDate'}
					,{ key: 0, name: '단말기 상태', column: 'state_name'}
				]
				,content: null
			}
			,is_modal: false
			,modal_option: {
				top: true
				,title: '단말기 등록'
				,bottom: false
				,width: '320px'
			}
			,is_all: false
			,terminal_status: ''
		}
	}
	,computed: {
		item_list: function(){
			let self = this
			return this.items.filter(function(item){
				for(let i = 0; i < self.codes.posStatus.length; i++){
					if(item.state === self.codes.posStatus[i].code){
						item.state_name = self.codes.posStatus[i].code_name
						item.state_color = self.codes.posStatus[i].color
						break
					}
				}

				return item
			})
		}
		,update_list: function(){
			let items = []
			this.items.filter(function(item){
				if(item.is_select){
					items.push(item.terminalIdx)
				}
			})
			console.log(items)
			return items
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'terminal'
					,data: this.search
				})

				if(result.success){
					this.items = result.data
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,toDetail: function(item){
			console.log(item)
			this.$emit('push', { name: 'AgencyDetail', params: { idx: item.idx }})
		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			//this.$emit('push', { name: this.$route.name, params: this.$route.params, query: this.search})

			this.getData()
		}

		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		,toItem: function(){
			this.is_modal = true
			this.item_merchant = {}
		}
		,close: function(){
			this.is_modal = false
			this.item_merchant = {}
		}
		,clear: function(){
			this.item_merchant = {}
		}
		,setMerchant: function(merchant){
			this.item_merchant = merchant
		}
		,save: async  function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'post'
					,url: 'terminal'
					,data: {
						shopIdx: this.item_merchant.shopIdx
						,shopName: this.item_merchant.shopName
						,serialNumber: this.item_terminal.serialNumber
					}
				})

				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
					await this.getSearch()
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
				this.close()
				this.item_terminal = {}
			}
		}
		,doUpdate: async function(){
			if(this.terminal_status === ''){
				this.$emit('setNotify', { type: 'error', message: '단말기 상태를 선택하세요'})
				return
			}
			if(this.update_list.length < 1){
				this.$emit('setNotify', { type: 'error', message: '선택된 항목이 없습니다'})
				return
			}
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'put'
					,url: 'terminal/' + this.terminal_status
					,data: {
						idxList: this.update_list
					}
				})

				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
					await this.getSearch()
					this.is_all = false
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
				this.close()
			}
		}
		,reset: function(){
			this.search = {
				page: 1
				,size: 10
				,search_type: ''
				,keyword: ''
				,agency_type: ''
				,state: ''
			}
			this.getData()
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
	,watch: {
		is_all:{
			handler: function(call){
				let self = this
				this.items.filter(function(item){
					self.$set(item, 'is_select', call)
				})
			}
		}
	}
}
</script>