<template>
	<div class="full-height">
		<div
			v-if="!item_merchant.shopIdx"
			class="full-height flex-column"
		>
			<div class="justify-space-between">
				<input
					v-model="search.name"
					class="input-box-inline vertical-middle flex-1 pa-10"
					placeholder="가맹점 조회"
				/>

				<v-icon
					small
					class="bg-identify color-eee pa-10 vertical-middle box cursor-pointer"
					@click="$emit('getSearch')"
				>mdi mdi-magnify</v-icon>
			</div>

			<ul class="mt-10 full-height overflow-y-auto pr-10">
				<li
					v-for="(merchant, index) in items"
					:key="'merchant_' + index"
					@click="setMerchant(merchant)"
					class="pa-10 mb-5 box radius-10 justify-space-between cursor-pointer"
				>
					<span class="flex-1 text-left">{{ merchant.shopName }}</span>
					<v-icon
						small
					>mdi mdi-chevron-right</v-icon>
				</li>
			</ul>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"

				@click="getSearch"
			></Pagination>
		</div>

		<div
			v-else
			class="full-height flex-column justify-center "
		>
			<div class=" full-width">
				<div
					class="box text-left pa-5-10 cursor-pointer"
					@click="clear"
				>
					<v-icon
						class="vertical-middle"
					>mdi mdi-chevron-left</v-icon>
					<span class="vertical-middle">{{ item_merchant.shopName }}</span>
				</div>
				<div class="mt-10">
					<input
						v-model="item_terminal.serialNumber"
						placeholder="단말기 번호"
						class="input-box pa-10"
						:rules="[rules.max(item_terminal, 'number', 25)]"
					>
				</div>
			</div>
			<div
				class="mt-auto justify-center pa-10"
			>
				<button
					class="pa-10-20 bg-identify mr-10"
					@click="save"
				>저장</button>
				<button
					class="pa-10-20 bg-bbb"
					@click="close"
				>취소</button>
			</div>
		</div>
	</div>
</template>

<script>
	import Pagination from "@/components/Pagination";
	export default {
		name: 'TerminalItem'
		,components: {Pagination}
		,props: ['item_merchant', 'search_merchant', 'item_terminal', 'rules', 'Axios']
		, data: function(){
			return {
				program: {
					name: '단말기 등록'
				}
				,search: {
					name: ''
					,page: 1
					,size: 10
				}
				,items: []
			}
		}
		,methods: {
			getData: async function(){
				try{
					const result = await this.Axios({
						method: 'get'
						,url: 'terminal/shops'
						,data: this.search
					})

					if(result.success){
						this.items = result.data
					}else{
						this.error_msg = result.message
					}
				}catch(e){
					console.log(e)
				}
			}
			,getSearch: function(){
				this.getData()
			}
			,setMerchant: function(merchant){
				this.$emit('setMerchant', merchant)
			}
			,close: function(){
				this.$emit('close')
			}
			,clear: function(){
				this.$emit('clear')
			}
			,save: function(){
				this.$emit('save')
			}
		}
		,created() {
			this.getData()
		}
	}
</script>